import { ProjectCardProps } from '@decub8/ui';
import { BadgeColors } from '@decub8/ui/dist/organisms/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useRouter } from 'next/router';

import { getChips } from '@src/components/HomepageComponents/MainEventCardContainer/utils';
import {
    BASE_IMAGE_URL,
    CONTENT,
    CONTRACT,
    DEFAULT_CHAIN_ID,
    NETWORKS,
} from '@src/config';
import { EventChipStatus, EventType } from '@src/ts/constants';
import { Project } from '@src/ts/interfaces';
import { generateSlug } from '@src/utils/format';

// hook to normalize the project events array to always have 3 items
export const useNormalizedEvents = (events: Project[], total: number) => {
    return [...events, ...Array(total - events.length).fill(null)];
};

dayjs.extend(utc);

export interface TempProjectCardProps extends ProjectCardProps {
    chainId?: number;
}

export enum ChipType {
    Private = 'Private',
    Seed = 'Seed',
    Public = 'Public',
    TokenClaim = 'TokenClaim',
    Standard = 'Standard',
    Exclusive = 'Exclusive',
    Acceleration = 'Acceleration',
    Incubation = 'Incubation',
}

// The hook that returns the props for the ProjectCard component, the Projectcards
// are the cards that are displayed on the home page
export const useSecondaryEventCard = (project: Project) => {
    const { name, hero, square_logo, announced, teaser, id, events, token } =
        project;

    // now only have one event in a single project because they are mapped that way to display a card for every event
    const [event] = events;

    const { network } = token || {};

    const router = useRouter();

    const project_slug = generateSlug(name || '');
    const event_slug = generateSlug(event?.name || '');

    const image_url = `${BASE_IMAGE_URL}/${id}/${announced ? hero : teaser}`;

    const logo_url = announced
        ? `${BASE_IMAGE_URL}/${id}/${square_logo}`
        : CONTENT.notAnnouncedSquareLogo || '';

    const is_crowdfunding = event?.type === EventType.Crowdfunding;
    const type = is_crowdfunding ? event?.crowdfunding_type : event?.type;

    // attempt to find a network in the NETWORKS object with a matching 'identifier' to get the network_logo
    const network_info = {
        logo:
            !announced || !network
                ? 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/content/Group%20229062.svg'
                : Object.values(NETWORKS).find(
                      (_network) => _network.identifier === network,
                  )?.network_logo,
        name: Object.values(NETWORKS).find(
            (_network) => _network.identifier === network,
        )?.network_name,
    };

    const event_date =
        event &&
        !event?.is_hidden &&
        dayjs(Number(event?.start_date) * 1000)
            .utc()
            .format('DD MMM YY / HH:mm UTC');

    return {
        logo_url,
        chips:
            !announced || !event
                ? [
                      {
                          text: EventChipStatus.ComingSoon,
                          badge_color: 'yellow' as BadgeColors,
                      },
                  ]
                : getChips(type, project.event_status),
        heading_text: !announced ? 'Guess next project' : name,
        card_data: [
            {
                label: `Event date`,
                values: [
                    {
                        value: (announced && event_date) || 'TBA',
                    },
                ],
            },
            {
                label: 'Total allocation',
                values: [
                    {
                        value:
                            (event?.total_allocation &&
                                (event?.type === EventType.TokenClaim
                                    ? event?.total_allocation
                                    : event?.total_allocation
                                    ? `${event?.total_allocation} USD`
                                    : '')) ||
                            'TBA',
                    },
                ],
            },
            {
                label: 'Event token price',

                values: [
                    {
                        value:
                            (announced &&
                                (event?.type === EventType.TokenClaim
                                    ? 'Live'
                                    : event?.token_price !== 'TBA'
                                    ? `${event?.token_price} USD`
                                    : 'TBA')) ||
                            'TBA',
                    },
                ],
            },
        ].filter((obj) => {
            return !(
                (obj.label === 'Event date' ||
                    obj.label === 'Event token price') &&
                !announced
            );
        }),
        image_url,
        network_info,
        company_logo: CONTENT.logo.mobile,
        company_name: CONTENT.companyName,
        protected_img_url:
            announced && event?.refund_deadline
                ? 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Protected-Green.svg'
                : '',
        protected_helper_text:
            'Refund your investment within a certain period after the event is complete.',
        has_cut_corners: CONTENT.hasCutProjectCards,
        announced,
        unannounced_btn_text:
            CONTRACT.BaseToken[DEFAULT_CHAIN_ID].symbol === 'DCB'
                ? `Win $25 DCB tokens`
                : 'Join community to guess who',
        handleCardClick: announced
            ? () => router.push(`/project/${project_slug}/${event_slug}`)
            : () => window.open(CONTENT.telegramUrl, '_blank', 'norefferer'),
    };
};

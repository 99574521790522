import React, { useEffect, useRef, useState } from 'react';
import { CompletedEvents, SortOrder } from '@decub8/ui';
import { useRouter } from 'next/router';

import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { EventType } from '@src/ts/constants';
import { ICompletedEvent } from '@src/ts/interfaces';
import { getCompletedEventsData } from '@src/utils/getters';

import { getDataArr, sortData } from './utils';

export interface ICompletedPageData {
    total_count: number;
    data: Record<number, ICompletedEvent[]>;
}

export enum CompletedEventsSortKey {
    Fdv = 'fdv',
    Athroi = 'ath_roi',
    Ath = 'ath',
    TotalRaised = 'total_raised',
    TotalDistributed = 'total_distributed',
    Participants = 'participants',
    EndedDate = 'ended_date',
}

const options = [
    { title: 'IDOs', id: EventType.Crowdfunding },
    { title: 'Learn to Earn', id: EventType.TokenClaim },
];

export const fetchCompletedEventsData = async (
    current_page: number,
    nav: EventType,
) => {
    const offset = (current_page - 1) * 6;

    const completed_events = await getCompletedEventsData(offset, 6, nav);

    return completed_events;
};

export const CompletedEventsSection: React.FC<{
    className?: string;
}> = ({ className }) => {
    const [current_page, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [nav, setNav] = useState<EventType>(options[0].id as EventType);
    const [sorted_event_data, setSortedEventData] = useState([]);
    const [selected_sort_key, setSelectedSortKey] = useState(
        CompletedEventsSortKey.EndedDate,
    );

    const [all_page_data, setAllPageData] = useState<
        Record<EventType, ICompletedPageData>
    >({
        [EventType.Crowdfunding]: {
            total_count: 0,
            data: {},
        },
        [EventType.TokenClaim]: {
            total_count: 0,
            data: {},
        },
    });

    const [selected_sort_order, selectedSortOrder] = useState(SortOrder.DSC);

    const completed_events_ref = useRef<HTMLDivElement>(null);

    const { contract_manager } = useGlobalContext();
    const router = useRouter();

    const current_page_data = all_page_data[nav].data[current_page];

    // reset the selected sort key when the nav changes
    useEffect(() => {
        setCurrentPage(1);
        setSelectedSortKey(CompletedEventsSortKey.EndedDate);
    }, [nav]);

    // reset the selected sort key when the nav changes
    useEffect(() => {
        if (current_page_data) {
            return;
        }

        setLoading(true);

        fetchCompletedEventsData(current_page, nav)
            .then((data) => {
                setAllPageData((prev) => ({
                    ...prev,
                    [nav]: {
                        total_count: prev[nav].total_count || data.total_count,
                        data: {
                            ...prev[nav].data,
                            [current_page]: data.data,
                        },
                    },
                }));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [current_page, nav, current_page_data]);

    useEffect(() => {
        const sorted_data = sortData(
            current_page_data || [],
            selected_sort_key,
            selected_sort_order,
        );

        setSortedEventData(getDataArr(sorted_data, router));
    }, [
        selected_sort_key,
        selected_sort_order,
        current_page_data,
        contract_manager,
        router,
    ]);

    const scrollToTop = () => {
        if (completed_events_ref.current) {
            completed_events_ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className={`${className}`}>
            <div ref={completed_events_ref}></div>
            <CompletedEvents
                total_events={
                    !all_page_data[nav].total_count
                        ? 6
                        : all_page_data[nav].total_count
                }
                nav={nav}
                selected_sort_order={selected_sort_order}
                setSelectedSortKey={setSelectedSortKey as (key: string) => void}
                setSelectedSortOrder={selectedSortOrder}
                selected_sort_key={selected_sort_key}
                setNav={setNav as (v: string) => void}
                nav_options={options}
                event_list_data={sorted_event_data}
                current_page={current_page}
                setCurrentPage={setCurrentPage}
                is_loading={loading}
                scrollToTop={scrollToTop}
            />
        </div>
    );
};

import React from 'react';
import { Chip, Media, Typography } from '@decub8/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const AmaCard = ({ ama_event, is_mobile }) => {
    return (
        <div
            onClick={() =>
                ama_event.is_complete
                    ? window.open(ama_event.youtube_url, '_blank', 'norefferer')
                    : undefined
            }
            className={`relative w-full mb-8 high-border bg-surface-level-one rounded-lg ${
                ama_event.is_complete ? 'cursor-pointer' : ''
            }`}
        >
            {ama_event.is_complete && !is_mobile && (
                <Chip
                    variant="secondary"
                    className="absolute -top-4 z-10 left-5"
                >
                    <span className="flex space-x-2">
                        <span className="relative flex h-3 w-3 my-auto">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-accent opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-accent"></span>
                        </span>
                        <span>Now live</span>
                    </span>
                </Chip>
            )}

            <div className="relative overflow-hidden">
                {/* gradient */}
                <div className="absolute inset-0">
                    <div className="ama-overlay-gradient absolute inset-0 rounded-lg"></div>
                </div>

                <div className="relative p-5">
                    <div
                        className={`relative ${
                            is_mobile ? '' : 'flex items-center'
                        }`}
                    >
                        {is_mobile && !ama_event.is_complete && (
                            <Chip className="ml-auto" variant="purple">
                                Scheduled
                            </Chip>
                        )}
                        {is_mobile && ama_event.is_complete && (
                            <div className="flex space-x-[10px] mb-5 relative z-20">
                                <span className="relative flex h-4 w-4 my-auto">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-accent opacity-75"></span>
                                    <span className="relative inline-flex rounded-full h-4 w-4 bg-accent"></span>
                                </span>
                                <Typography allBold>Now live</Typography>
                            </div>
                        )}

                        <Typography
                            size={is_mobile ? '2xl' : '3xl'}
                            allBold
                            className={`${
                                is_mobile ? 'my-5 max-w-[240px]' : 'mr-[56px]'
                            }`}
                        >
                            {ama_event.title}
                        </Typography>

                        <div
                            className={`${
                                is_mobile
                                    ? 'space-y-[10px]'
                                    : 'flex space-x-[48px]'
                            }`}
                        >
                            <div className="flex space-x-2 items-center z-20">
                                <div className="w-8 h-8 flex items-center justify-center rounded-full low-border bg-surface-level-two">
                                    <Media
                                        variant="calendar"
                                        size={0}
                                        className="w-[14px] text-primary"
                                    />
                                </div>
                                <Typography variant="secondary">
                                    {ama_event.date}
                                </Typography>
                            </div>
                            <div className="flex space-x-2 items-center z-20">
                                <div className="w-8 h-8 flex items-center justify-center rounded-full low-border bg-surface-level-two">
                                    <Media
                                        size={0}
                                        className="w-[20px] text-primary"
                                        variant="youtube"
                                    />
                                </div>
                                <Typography variant="secondary">
                                    YouTube
                                </Typography>
                            </div>
                        </div>

                        {!is_mobile && !ama_event.is_complete && (
                            <Chip className="ml-auto" variant="purple">
                                Scheduled
                            </Chip>
                        )}
                    </div>

                    {ama_event.is_complete && (
                        <div
                            className={`absolute right-5 bg-accent rounded-full shadow-accent border border-accent border-opacity-5 flex items-center justify-center z-20 ${
                                is_mobile
                                    ? 'h-[48px] w-[48px] top-5'
                                    : 'h-[48px] w-[48px] top-1/2 transform -translate-y-1/2'
                            }`}
                        >
                            <Media variant="play-arrow" className="h-[16px]" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

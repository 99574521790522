import { api_client } from '@src/bootstrap/index';
import { CONFIG } from '@src/services/confg';
import { ConfigKey } from '@src/ts/constants';
import { BackendConfigItem } from '@src/ts/interfaces';

export const getFillerCard = async () => {
    try {
        const {
            retrieveConfig: { value },
        } = await api_client.query<{ retrieveConfig: BackendConfigItem }>({
            query: CONFIG.GET_CONFIG_ITEM,
            variables: {
                key: ConfigKey.EventPlaceholderCardImg,
            },
        });

        return value;
    } catch (e) {
        console.error(
            'No config value added for event_placeholder_card_img',
            e,
        );
        return '';
    }
};

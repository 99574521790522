import { MainEventCard } from '@decub8/ui';

import { ProjectContextProvider } from '@src/components/Project/context';
import { Project } from '@src/ts/interfaces';

import { useMainEventCard } from './hooks';

export enum DisplayStatus {
    Ongoing = 'Ongoing',
    Upcoming = 'Upcoming',
}

export const MainEventCardComponent: React.FC<{
    next_project_event: Project;
}> = ({ next_project_event }) => {
    const props = useMainEventCard(next_project_event);

    return <MainEventCard {...props} />;
};

export const MainEventCardContainer: React.FC<{
    next_project_event: Project;
    className?: string;
}> = ({ next_project_event }) => {
    return (
        <ProjectContextProvider
            project={next_project_event}
            event={
                next_project_event.events[0]
                    ? {
                          ...next_project_event.events[0],
                          id: Number(next_project_event.events[0]?.id), // need to id convert to number
                      }
                    : undefined
            }
            children={
                <MainEventCardComponent
                    next_project_event={next_project_event}
                />
            }
        />
    );
};

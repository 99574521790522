import { useEffect, useState } from 'react';
import { UpcomingEventsV2 } from '@decub8/ui';
import { useRouter } from 'next/router';

import { BASE_IMAGE_URL } from '@src/config';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { EventChipStatus, EventType } from '@src/ts/constants';
import { generateSlug } from '@src/utils/format';
import { getEventStatus } from '@src/utils/getters';

export enum DisplayStatus {
    Ongoing = 'Ongoing',
    Upcoming = 'Upcoming',
}

export const UpcomingEventsContainer: React.FC<{
    className?: string;
}> = ({ className }) => {
    const [upcoming_events_data, setUpcomingEventsData] = useState([]);

    const router = useRouter();
    const { contract_manager, projects } = useGlobalContext();

    useEffect(() => {
        const fetchUpcomingEvents = async () => {
            const events_data = await Promise.all(
                projects.map(
                    async ({ announced, events, id, hero, name, token }) => {
                        // skip projects with no events and test projects
                        if (
                            !events ||
                            events.length === 0 ||
                            !!events[0].is_test
                        )
                            return null;

                        const event_status = await getEventStatus(
                            events[0],
                            announced,
                            contract_manager,
                        );

                        if (
                            event_status === EventChipStatus.ComingSoon &&
                            announced
                        ) {
                            return {
                                image: `${BASE_IMAGE_URL}/${id}/${hero}`,
                                name: events[0].name,
                                data: [
                                    {
                                        label: 'Total allocation',
                                        value: events[0].total_allocation,
                                    },
                                    {
                                        label: 'Event token price',
                                        value:
                                            events[0].type ===
                                            EventType.TokenClaim
                                                ? 'Live'
                                                : events[0].token_price ===
                                                  'TBA'
                                                ? 'TBA'
                                                : `${events[0].token_price} USD`,
                                    },
                                    {
                                        label: 'FDV',
                                        value:
                                            token.fully_diluted_valuation ||
                                            'TBA',
                                    },
                                ],
                                handleClick: () =>
                                    router.push(
                                        `/project/${generateSlug(
                                            name,
                                        )}/${generateSlug(events[0].name)}`,
                                    ),
                            };
                        }
                        return null; // Return null if event status is not 'Coming soon'
                    },
                ),
            );
            // Filter out null values from the result array
            const filtered_events = events_data.filter((event) => !!event);

            setUpcomingEventsData(filtered_events);
        };
        fetchUpcomingEvents();
    }, [projects, router, contract_manager]);
    return (
        <div className={className}>
            <UpcomingEventsV2 data={upcoming_events_data} />
        </div>
    );
};

import React, { useEffect, useRef, useState } from 'react';
import { SecondaryEventCard, Typography, useBreakpoint } from '@decub8/ui';

import { Project } from '@src/ts/interfaces';

import { FillerCard } from './FillerCard';
import { useNormalizedEvents, useSecondaryEventCard } from './hooks';

const useDotNavigation = (itemsCount: number) => {
    const [scroll_position, setScrollPosition] = useState(0);

    const scroll_container_ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (scroll_container_ref.current) {
                const scrollLeft = scroll_container_ref.current.scrollLeft;

                const maxScrollLeft =
                    scroll_container_ref.current.scrollWidth -
                    scroll_container_ref.current.clientWidth;

                const scrollRatio = scrollLeft / maxScrollLeft;

                setScrollPosition(
                    Math.min(
                        Math.round(scrollRatio * (itemsCount - 1)),
                        itemsCount - 1,
                    ),
                );
            }
        };

        const scrollContainer = scroll_container_ref.current;

        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);

            return () =>
                scrollContainer.removeEventListener('scroll', handleScroll);
        }
    }, [itemsCount]);

    return { scroll_position, scroll_container_ref };
};

export const SecondaryEventCardsContainer: React.FC<{
    className?: string;
    secondary_events?: Project[];
}> = ({ className, secondary_events }) => {
    const { scroll_position, scroll_container_ref } = useDotNavigation(3);

    const breakpoint = useBreakpoint();
    const is_mobile = breakpoint < 1180;

    // custom hook to normalize the events array
    const normalized_events = useNormalizedEvents(secondary_events, 3);

    return (
        <div className={`${className}`}>
            <Typography
                allBold
                size={is_mobile ? '2xl' : '3xl'}
                className="mb-8"
            >
                Live and upcoming events
            </Typography>
            <div
                className="overflow-x-scroll w-full"
                ref={scroll_container_ref}
            >
                <div className="flex space-x-5">
                    {normalized_events.map((project, index) => {
                        if (project) {
                            const card_props = useSecondaryEventCard(project);
                            return (
                                <SecondaryEventCard
                                    key={index}
                                    {...card_props}
                                />
                            );
                        } else {
                            return <FillerCard />;
                        }
                    })}
                </div>
            </div>
            <div
                className={`flex justify-center mt-4 ${
                    is_mobile ? '' : 'hidden'
                }`}
            >
                {[0, 1, 2].map((index) => (
                    <div
                        key={index}
                        className={`w-2 h-2 rounded-full mx-[6px] ${
                            scroll_position === index
                                ? 'bg-primary'
                                : 'bg-surface-level-four'
                        }`}
                    />
                ))}
            </div>
        </div>
    );
};
